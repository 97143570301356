import React from "react";
import { useSelector } from 'react-redux';
import { Button, Placeholder } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

/** Components */
import BonusTimer from "./BonusTimer";

/** Utilities */
import isValidBonus from "../utilities/is-valid-bonus";
import useText from "../utilities/use-text";

export default function DepositBonusCard({ bonus, selectBonusToggle, isSelected }) {
    const user = useSelector((state) => state.user.data);
    const segment = useSelector((state) => state?.global?.data?.segment);
    const text = useText();

    const showDepositMinAmount = () => {
        const option = bonus.currencyList?.filter(item => {
            return item?.currency?.id === user?.currency?.id;
        })[0];

        return `${text('deposit_bonus_card_min_deposit')} ${user?.currency?.sign}${option?.depositMinAmount}`;
    }

    if (!bonus) {
        return (
            <Placeholder as="button" animation="glow" className="bonus-card-placeholder">
                <Placeholder xs={12} />
            </Placeholder>
        )
    }

    return (
        <>
            {
                !isValidBonus({
                    bonus,
                    user,
                    type: 'DEPOSIT',
                    showHidden: false,
                    segmentId: segment?.id
                }) ? null : (
                    <Button
                        id={`promo-${bonus.promoCode}`}
                        className={`bonus-card ${isSelected && "bonus-card-selected"} animate__animated animate__faster animate__fadeIn`}
                        onClick={selectBonusToggle}
                    >
                        {
                            bonus?.promoCode?.includes('TIMER') && (
                                <p className="bonus-card-timer-wrapper animate__animated animate__fadeIn animate__delay-1s">
                                    <BonusTimer {...{ bonus }} />
                                </p>
                            )
                        }
                        {
                            isSelected && (
                                <div className="bonus-card-check">
                                    <FontAwesomeIcon icon={faCircleCheck} className="white-100" />
                                </div>
                            )
                        }
                        <img src={bonus?.content?.imageUrl} />
                        <div className="bonus-card-text-wrapper">
                            <div>
                                <p className="bonus-card-title">{bonus?.content?.title}</p>
                            </div>

                            <p className="bonus-card-details">{showDepositMinAmount()}</p>
                        </div>
                    </Button>
                )
            }
        </>
    )
}