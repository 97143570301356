import React, { useRef } from "react";
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CategoryTitle from "./CategoryTitle";

/** Components */
import ScrollButtons from './ScrollButtons';
import BonusTile from "./BonusTile";
import QuestionMark from "./QuestionMark";

/** Utilities */
import isValidBonus from "../utilities/is-valid-bonus";
import useText from "../utilities/use-text";

export default function BonusRowCategory() {
    const segment = useSelector((state) => state?.global?.data?.segment);
    const country = useSelector((state) => state?.global?.data?.country);
    const user = useSelector((state) => state.user.data)
    const bonuses = useSelector((state) => state.bonuses.list);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const text = useText();

    const container = useRef(null);

    return (
        <>
            {
                Object.keys(bonuses)?.length > 0 && (
                    <Container fluid="lg" className="category-container row-container section-margin" id="bonus-row-category">
                        <div className="bonus-row-title-container mb-3">
                            <CategoryTitle title={user?.depositsMade > 0 ? text('bonus_row_title_2') : text('bonus_row_title')} className="title-xs mb-0" />
                            <QuestionMark placement="top" text={text('question_mark_bonus')} />
                        </div>

                        <div className="position-relative">
                            <div className="bonus-row-wrapper" ref={container}>
                                {
                                    Object.entries(bonuses)?.map(([key, bonus], index) => {
                                        const temp = {
                                            currency: { id: country?.currency?.id },
                                            bonusesClaimed: '',
                                            depositsMade: 0,
                                        }

                                        if (isValidBonus({
                                            bonus,
                                            user: userLoggedIn ? user : temp,
                                            type: 'DEPOSIT',
                                            showHidden: false,
                                            segmentId: segment.id
                                        })) {
                                            return <BonusTile bonus={bonus} key={`bonus-row-${index}-${key}`} />
                                        }
                                    })
                                }
                            </div>
                            <ScrollButtons container={container} />
                        </div>
                    </Container>
                )
            }
        </>
    );
}