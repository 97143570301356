export default function isValidBonus({ bonus, user, type, showHidden, segmentId }) {

    /** Checks for bonus for logged in users */
    if (bonus && Object.keys(user).length > 0) {

        /** Getting the currency option */
        const option = bonus.currencyList.filter(item => {
            return item.currency.id === user.currency.id;
        })[0];

        /** Check if bonus supports user currency */
        if (!option) { return false; }

        /** If user already claimed the bonus */
        if (!bonus.isInfinite && user.bonusesClaimed?.includes(bonus.promoCode)) { return false; }
        //if (user.bonusesClaimed?.includes(bonus.promoCode)) { return false; }

        /** If bonus deposit flow  position is different */
        if (bonus.depositFlowPosition !== user.depositsMade + 1 && bonus.depositFlowPosition !== 0) { return false; }

        /** If this is a hidden bonus validated through search */
        if (bonus.isHidden && !showHidden) { return false; }

        /** Type can be ALL, FREE, DEPOSIT */
        if (type !== 'ALL' && type !== bonus.activationType) { return false; }

        /** If the current player is eligible for the bonus  */
        if (bonus?.playerList?.length > 0 && !bonus?.playerList?.includes(user.id)) { return false; }

        /** Check if a bonus is a part of a segment */
        if (bonus?.segments?.length > 0) {

            /** If no segment id is supplied */
            if (!segmentId) return false;

            /** Check if the segment matches */
            const segment = bonus.segments.filter(segment => {
                return segment.id === segmentId;
            })[0];

            if (!segment) { return false; }
        }

        return true;
    }
    else if (bonus && bonus?.segments?.length > 0) {
        
        /** If no segment id is supplied */
        if (!segmentId) return false;

        /** Check if the segment matches */
        const segment = bonus.segments.filter(segment => {
            return segment.id === segmentId;
        })[0];

        if (!segment) { return false; }

        return true;
    }

    return false;
}