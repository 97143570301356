import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, FloatingLabel, Form, Container } from 'react-bootstrap';
import { dbVerifyEmail } from "../utilities/api-functions";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

/** Components */
import ErrorHandler from "../components/ErrorHandler";
import Loading from "../components/Loading";

/** Utilities */
import useText from "../utilities/use-text";

export default function VerifyEmail() {
    const location = useLocation();
    const text = useText();

    const [token, setToken] = useState(null);
    const [errors, setErrors] = useState([]);

    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        /** If token is present, set token */
        if (params.token) {
            dbVerifyEmail(token)
                .then((res) => {
                    // console.log(res)
                    if (res?.data?.message) {
                        setIsValid(true);
                        setIsLoading(false);

                        // setTimeout(() => {
                        //     window.location.href = window.location.origin;
                        // }, 1000)
                    }
                })
                .catch(error => {
                    setErrors([...error.response.data.errors]);
                });
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>{text('page_verify_email')}</title>
            </Helmet>

            <Container>
                <ErrorHandler {...{ errors, setErrors }} />
                {
                    isLoading && (
                        <div className="verify-email-container">
                            <div className="verify-email-wrapper">
                                <Loading />
                            </div>
                        </div>
                    )
                }

                {
                    !isLoading && <EmailVerified {...{ isValid }} />
                }
            </Container>
        </>
    );
}

function EmailVerified({ isValid }) {

    const navigate = useNavigate();

    useEffect(() => {
        if (isValid) {
            setTimeout(() => {
                navigate("/?login")
            }, 5000)
        }
    }, []);
    
    return (
        isValid ? (
            <div className="verify-email-container">
                <div className="verify-email-wrapper">
                    <div className="verify-email-icon-wrap-success">
                        <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                <path class="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z" />
                                <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" />
                            </g>
                        </svg>
                    </div>
                    <div className="animate__animated animate__fadeInUp">
                        <h1 className="title-sm text-center mt-2">Success! Your email is verified.</h1>
                        <p className="text-center">You will now be redirected to the login page...</p>
                    </div>
                </div>
            </div>
        ) : (
            <div className="verify-email-container">
                <div className="verify-email-wrapper">
                    <div className="verify-email-icon-wrap-fail">
                        <FontAwesomeIcon icon={faClose} />
                    </div>
                    <div className="animate__animated animate__fadeInUp">
                        <h1 className="title-sm text-center mt-2">Error! Something went wrong.</h1>
                        <p className="text-center font-mid" style={{ maxWidth: "600px" }}>Your email is either already verified or an error has occured. Please, send us an email at <b>orozino@support.com</b> if you are having trouble logging in.</p>
                    </div>
                </div>
            </div>
        )
    )
}