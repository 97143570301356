import React, { useState, useEffect } from "react";
import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import QuestionMark from "../spinsoft-core-client-subtree/components/QuestionMark";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faClock } from '@fortawesome/free-solid-svg-icons';


/** Utilities */
import useText from "../spinsoft-core-client-subtree/utilities/use-text";

export default function FirstDepositPromo({ page }) {
    const user = useSelector((state) => state.user.data);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const text = useText();

    return (
        <>
            {
                (!userLoggedIn || user?.depositsMade === 0) && (
                    <>
                        {
                            page === 'registration' && (
                                <div className="fd_promo_registration_container">
                                    <img src="https://spt-assets.b-cdn.net/orozino/assets/arrow-lottery-color.png" className="fd_promo_registration_arrow" />
                                    <div className="fd_promo_registration_content">
                                        <p className="mb-0 font-full">{text('fd_promo_registration_title')}</p>
                                    </div>
                                    <img className="fd_promo_registration_image" src="https://spt-assets.b-cdn.net/orozino/assets/30fs-registration-promo.png" />
                                </div>
                                // <div className="fd_promo_registration_container">
                                //     {/* <img src="https://spt-assets.b-cdn.net/orozino/assets/arrow-lottery.png" className="fd_promo_deposit_arrow" /> */}
                                //     <div className="fd_promo_deposit_content">
                                //         <p className="mb-0 font-mid">Certified by the top payment providers</p>
                                //     </div>
                                //     <div className="fd_promo_deposit_images">
                                //         <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-visa.png" />
                                //         <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-mc.png" />
                                //         <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-interac.png" />
                                //         <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-bitcoin.png" />
                                //         <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-etherium.png" />
                                //         <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-tether.png" />
                                //         <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-ripple.png" />
                                //     </div>
                                // </div>
                            )
                        }

                        {
                            page === 'deposit' && user?.currency?.code === 'CAD' && (
                                <div className="fd_promo_deposit_container">
                                    <img src="https://spt-assets.b-cdn.net/orozino/assets/lottery-cat.png" />
                                    <div className="fd_promo_deposit_content">
                                        <p className="fd_promo_deposit_content_title font-full">{text('fd_promo_deposit_title_lottery')}</p>
                                        <p className="fd_promo_deposit_content_money mb-0 font-full">$100,000</p>
                                        <p className="fd_promo_deposit-timer-wrapper animate__animated animate__fadeIn animate__delay-1s">
                                            <LotteryDropTimer />
                                        </p>
                                        <div className="fd_promo_deposit-question-mark-wrapper">
                                            <QuestionMark placement="bottom" text={text('question_mark_deposit_lottery')} />
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {/* {
                            page === 'deposit' && user?.currency?.code === 'CAD' && (
                                <div className="fd_promo_deposit_container">
                                    <img src="https://spt-assets.b-cdn.net/orozino/assets/cashier-banner-interac.png" />
                                    <div className="fd_promo_deposit_content">
                                        <p className="fd_promo_deposit_content_title mb-0 font-full">{text('fd_promo_deposit_title_interac')}</p>
                                        <p className="fd_promo_deposit_content_subtitle mb-0 font-mid">{text('fd_promo_deposit_subtitle_interac')}</p>
                                    </div>
                                    <img src="https://spt-assets.b-cdn.net/orozino/assets/cashier-banner-leaf.png" />
                                </div>
                            )
                        }

                        {
                            page === 'deposit' && user?.currency?.code === 'BRL' && (
                                <div className="fd_promo_deposit_container">
                                    <img src="https://spt-assets.b-cdn.net/orozino/assets/cashier-banner-pix.png" />
                                    <div className="fd_promo_deposit_content">
                                        <p className="fd_promo_deposit_content_title mb-0 font-full">{text('fd_promo_deposit_title_pix')}</p>
                                        <p className="fd_promo_deposit_content_subtitle mb-0 font-mid">{text('fd_promo_deposit_subtitle_pix')}</p>
                                    </div>
                                    <img src="https://spt-assets.b-cdn.net/orozino/assets/cashier-banner-brazil.png" />
                                </div>
                            )
                        } */}

                        {/* {
                            page === 'home' && (
                                <Container fluid="lg" className="section-margin animate__animated animate__faster animate__fadeIn">
                                    <div className="fd_promo_home_container">
                                        <div className="fd_promo_home_content">
                                            <div className="fd_promo_home_image_wrapper">
                                                <img src="https://spt-assets.b-cdn.net/orozino/assets/secure-logos.png" className="fd_promo_home_image" alt="" />
                                            </div>

                                            <div className="fd_promo_home_text">
                                                <p className="mb-1 fd_promo_badge">{text('first_deposit_promo_badge')}</p>
                                                <p className="subtitle mb-0">{text('first_deposit_promo_title_home')}</p>
                                            </div>
                                        </div>
                                        <div className="fd_promo_home_btn_container">
                                            <Link to={userLoggedIn ? '?cashier' : "?register"} className="btn btn-main">{text('first_deposit_promo_cta')}</Link>
                                        </div>
                                    </div>
                                </Container>
                            )
                        } */}
                    </>
                )
            }
        </>
    )
}

function LotteryDropTimer() {
    const [timer, setTimer] = useState('');

    function leadingZero(num) {
        num = num.toString();
        while (num.length < 2) num = "0" + num;
        return num;
    }

    function getTime() {
        const today = new Date();
        let hours = today.getHours();
        let mimuntes = today.getMinutes();
        let seconds = today.getSeconds();
        let secondsFromStartOfDate = (hours * 60 * 60) + (mimuntes * 60) + seconds;
        let secondsUntilEndOfDate = (24 * 60 * 60) - secondsFromStartOfDate;

        let tempHours = Math.floor((secondsUntilEndOfDate / 60 / 60) % 24);
        let tempMinutes = Math.floor((secondsUntilEndOfDate / 60) % 60);
        let tempSeconds = Math.floor(secondsUntilEndOfDate % 60);

        return `${leadingZero(tempHours)}:${leadingZero(tempMinutes)}:${leadingZero(tempSeconds)}`;
    }

    useEffect(() => {
        const time = getTime();
        !timer && setTimer(time);

        const timeout = setTimeout(() => {
            const time = getTime();
            setTimer(time);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        }

    }, [timer])

    return (
        <span>
            <FontAwesomeIcon icon={faClock} className="me-2" />
            <span>{timer}</span>
        </span>
    )
}