
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { dbCheckAvailableFreespins } from "../utilities/api-functions";
import { ModalWelcomeOffer } from "../../components/RetentionModals";

/** Utilities */
import useText from "../utilities/use-text";

export default function FreespinsRow() {
    const user = useSelector((state) => state.user.data);
    const text = useText();

    const [game, setGame] = useState([]);
    const [campaign, setCampaign] = useState({});

    const [modalWelcomeOfferVisible, setModalWelcomeOfferVisible] = useState(false);

    useEffect(() => {
        /** Check if user is a populated object */
        if (Object.keys(user).length > 0) {

            /** If user have a freespin campaign  */
            if (user.bonus[0]?.freespinsCampaignId) {

                /** Check if freespin campaign has spins available */
                dbCheckAvailableFreespins({ freespin_id: user.bonus[0].freespinsCampaignId })
                    .then(async res => {
                        if ((res?.data?.campaign?.status === 'Active' || res?.data?.campaign?.status === 'Played') && res?.data?.campaign?.is_canceled === 0 && res?.data?.campaign?.quantity_left > 0) {
                            setGame(res?.data?.games[0]);
                            const quantity_left = res.data.campaign.quantity_left;
                            setCampaign({ quantity_left: quantity_left })
                        }

                        if ((res?.data?.campaign?.rounds - res?.data?.campaign?.roundsPlayed > 0)) {
                            setGame(res?.data?.games[0]);
                            const quantity_left = res?.data?.campaign?.rounds - res?.data?.campaign?.roundsPlayed;
                            setCampaign({ quantity_left: quantity_left })
                        }

                        /**************************/
                        /** Activating ModalWelcomeOffer modal */
                        // if (
                        //     user.bonus[0]?.promoCode.includes('PHONE_VERIFICATION') && 
                        //     user?.depositsMade < 1 && 
                        //     res?.data?.campaign?.quantity_left === 0
                        // ) {
                        //     !modalWelcomeOfferVisible && setModalWelcomeOfferVisible(true);
                        // }
                    });
            }
        }
    }, [user]);

    return (
        <Container fluid="lg">
            {
                game.id && (
                    <section className="section-margin animate__animated animate__faster animate__fadeIn">
                        <div to={`game/${game.id}`} className="freespins-row-container">
                            <div className="freespins-row-image-wrapper" >
                                <img src={game.image} className="freespins-row-image" />
                            </div>
                            <div className="freespins-row-text-wrapper">
                                <h2 className="subtitle-sm white-87 mb-1">
                                    {text('freespin_row_title', { number: campaign?.quantity_left, game: game?.name })}
                                </h2>
                            </div>
                            <div className="freespins-row-button-wrapper">
                                <Link to={`game/${game.id}`} className="btn-main">
                                    {text('freespin_row_cta')}
                                </Link>
                            </div>
                        </div>
                    </section>
                )
            }
            <ModalWelcomeOffer {...{ show: modalWelcomeOfferVisible, setShow: setModalWelcomeOfferVisible }}/>
        </Container>
    )
}