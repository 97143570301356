import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

/** Utilities */
import useText from "../spinsoft-core-client-subtree/utilities/use-text";

export default function DepositMethod({ setPaymentMethod, transitionToStep, amount }) {
    const user = useSelector((state) => state.user.data)
    const text = useText();

    return (
        <div className="deposit-payment-method-container">
            <h2 className="subtitle font-mid deposit-payment-method-title">{text('deposit_payment_method_title')}</h2>
            {
                {
                    'EUR': <PaymentMethods_EUR {...{ setPaymentMethod, transitionToStep }} />,
                    'USD': <PaymentMethods_USD {...{ setPaymentMethod, transitionToStep }} />,
                    'CAD': <PaymentMethods_CAD {...{ setPaymentMethod, transitionToStep, amount }} />,
                    'NZD': <PaymentMethods_NZD {...{ setPaymentMethod, transitionToStep }} />,
                    'BRL': <PaymentMethods_BRL {...{ setPaymentMethod, transitionToStep, amount }} />,
                    'PEN': <PaymentMethods_PEN {...{ setPaymentMethod, transitionToStep }} />,
                }[user?.currency?.code]
            }
            {/* <div className='deposit_amount_section_images'>
                <img src="https://spt-assets.b-cdn.net/orozino/assets/secure-logo.png" />
                <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-pcidss.png" />
            </div> */}
        </div>
    )
}

/** EUR */
function PaymentMethods_EUR({ setPaymentMethod, transitionToStep }) {
    return (
        <>
            <Button onClick={() => { setPaymentMethod('payment_card_eur_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment visa">
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa" />
                    <span>Visa / Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Crypto {...{ setPaymentMethod, transitionToStep }} />
        </>
    )
}

/** USD */
function PaymentMethods_USD({ setPaymentMethod, transitionToStep }) {
    return (
        <>
            <Button onClick={() => { setPaymentMethod('payment_card_usd_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment visa">
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa" />
                    <span>Visa / Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            {/* <Crypto {...{ setPaymentMethod, transitionToStep }} /> */}
        </>
    )
}

/** CAD */
function PaymentMethods_CAD({ setPaymentMethod, transitionToStep, amount }) {
    const text = useText();
    const minAmount = 15;
    
    return (
        <>
            <Button onClick={() => { setPaymentMethod('interac_aggregated_cad_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment interac">
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/interac-color-logo-4.png" alt="Interac" />
                    <span>Interac®</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            {
                amount < minAmount && (
                    <p className="font-mid mt-4 mb-3" style={{fontSize: "12px"}}>{text('deposit_method_minimum_deposit_amount')} <b>C$ {minAmount}</b>.</p>
                )
            }

            <Button onClick={() => { setPaymentMethod('payment_card_cad_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment visa"
                disabled={amount < minAmount} >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa" />
                    <span>Visa / Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Crypto {...{ setPaymentMethod, transitionToStep, amount, minAmount }} />
        </>
    )
}

/** BRL */
function PaymentMethods_BRL({ setPaymentMethod, transitionToStep, amount }) {
    const text = useText();
    const minAmount = 65;

    return (
        <>
            <Button onClick={() => { setPaymentMethod('pix_qr_brl_invoice'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment visa">
                <div>
                    <img src="https://cherryspins.b-cdn.net/orozino-test/pix.png" alt="Pix" />
                    <span>Pix</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Button onClick={() => { setPaymentMethod('pix_brl_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment visa">
                <div>
                    <img src="https://cherryspins.b-cdn.net/orozino-test/pix.png" alt="Pix" />
                    <span>Pix 2</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            {
                amount < minAmount && (
                    <p className="font-mid mt-4 mb-3" style={{fontSize: "12px"}}>{text('deposit_method_minimum_deposit_amount')} <b>R$ {minAmount}</b>.</p>
                )
            }

            <Button onClick={() => { setPaymentMethod('payment_card_brl_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment visa"
                disabled={amount < minAmount} >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa" />
                    <span>Visa / Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Crypto {...{ setPaymentMethod, transitionToStep, amount, minAmount }} />
        </>
    )
}

/** PEN */
function PaymentMethods_PEN({ setPaymentMethod, transitionToStep }) {
    return (
        <>
            <Button onClick={() => { setPaymentMethod('payment_card_pen_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment visa">
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa" />
                    <span>Visa / Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            {/* <Crypto {...{ setPaymentMethod, transitionToStep }} /> */}
        </>
    )
}

/** NZD */
function PaymentMethods_NZD({ setPaymentMethod, transitionToStep }) {
    return (
        <>
            <Button onClick={() => { setPaymentMethod('payment_card_nzd_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment visa">
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa" />
                    <span>Visa / Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            {/* <Crypto {...{ setPaymentMethod, transitionToStep }} /> */}
        </>
    )
}

/********************/
/** Crypto options */
function Crypto({ setPaymentMethod, transitionToStep, amount, minAmount }) {
    const text = useText();
    const [show, setShow] = useState(false);

    return (
        <>
            {/* <Button onClick={() => { setShow(true) }}
                className="btn-secondary btn-choose-payment coinspaid">
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/coinspaid-logo.png" alt="Coinspaid" />
                    <span>Crypto</span>
                    <span className="choose-payment-bonus">{text('deposit_payment_method_bonus')}</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Modal show={show} onHide={() => { setShow(false) }} centered>
                <Modal.Header closeButton >
                    <span className="subtitle font-mid">Choose your currency</span>
                </Modal.Header>
                <Modal.Body className='text-center pt-0'> */}
            <Button onClick={() => { setShow(false); setPaymentMethod('bitcoin_eur_hpp'); transitionToStep(3); }}
                className="btn-secondary btn-choose-payment coinspaid"
                disabled={amount < minAmount}>
                <div>
                    <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-bitcoin.png" alt="Bitcoin" />
                    <span>Bitcoin</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Button onClick={() => { setShow(false); setPaymentMethod('bitcoin_cash_eur_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid"
                disabled={amount < minAmount} >
                <div>
                    <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-bitcoin-cash.png" alt="Bitcoin Cash" />
                    <span>Bitcoin Cash</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Button onClick={() => { setShow(false); setPaymentMethod('tether_trc20_eur_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid"
                disabled={amount < minAmount}>
                <div>
                    <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-tether-trc20.png" alt="Tether TRC20" />
                    <span>Tether TRC20</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Button onClick={() => { setShow(false); setPaymentMethod('tether_erc20_eur_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid"
                disabled={amount < minAmount}>
                <div>
                    <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-tether-erc20.png" alt="Tether ERC20" />
                    <span>Tether ERC20</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Button onClick={() => { setShow(false); setPaymentMethod('ethereum_eur_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid"
                disabled={amount < minAmount}>
                <div>
                    <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-etherium.png" alt="Ethereum" />
                    <span>Ethereum</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Button onClick={() => { setShow(false); setPaymentMethod('ripple_eur_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid"
                disabled={amount < minAmount}>
                <div>
                    <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-ripple.png" alt="Ripple" />
                    <span>Ripple</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Button onClick={() => { setShow(false); setPaymentMethod('litecoin_eur_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid"
                disabled={amount < minAmount}>
                <div>
                    <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-litecoin.png" alt="Litecoin" />
                    <span>Litecoin</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>
            {/* </Modal.Body>
            </Modal> */}
        </>
    )
}