import { useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/** Metadata */
import metadata from '../../metadata.json';

export default function IPBlock() {
    const ipLocation = useSelector((state) => state.global.data.location);
    const navigate = useNavigate();

    useEffect(() => {
        if (metadata?.restricted?.countries.includes(ipLocation?.country) || 
            metadata?.restricted?.regions.includes(ipLocation?.region)) {
                navigate(`/region-blocked`, { replace: true });
            }
    }, []);

    return null;
}