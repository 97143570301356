import React, { useEffect, useState, useRef } from "react";
import { Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicket } from '@fortawesome/free-solid-svg-icons'
import { dbBonusRoll, dbUpdateUserInfo } from "../spinsoft-core-client-subtree/utilities/api-functions";
import QuestionMark from "../spinsoft-core-client-subtree/components/QuestionMark";

/** Components */
import CategoryTitle from "../spinsoft-core-client-subtree/components/CategoryTitle";
import ErrorHandler from "../spinsoft-core-client-subtree/components/ErrorHandler";
import Notification from "../spinsoft-core-client-subtree/components/Notification";

/** Images */
// import itemRollFrontWin from "../assets/images/item-roll-front-win.png"
// import itemRollFrontDefault from "../assets/images/item-roll-front-default.png"

/** Utilities */
import useText from "../spinsoft-core-client-subtree/utilities/use-text";

const itemRollFrontWin = 'https://cherryspins.b-cdn.net/orozino-test/back-win-9.png';
const itemRollFrontDefault = 'https://cherryspins.b-cdn.net/orozino-test/back-5.png';

/** Messages */
const messages = {
    en: {
        cashback_roll_title: "Pick your prize",
        cashback_roll_next_ticket: "Next ticket unlocks in",
        cashback_roll_deposit_title: "Deposit now and get 2 tickets for free!",
        cashback_roll_btn_deposit: "Get tickets",
        cashback_roll_tickets_left: `Tickets left:`,
    }
}

export default function CashbackRoll() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.data);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const text = useText();

    /*******************/
    /** Dates */
    let now = new Date();
    let endDate = new Date(user?.cashbackLastPlayed || '1969-01-01');
    endDate = endDate.setDate(endDate.getDate() + 1);

    /*******************/
    /** State */
    const [errors, setErrors] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [isEligibleToRoll, setIsEligibleToRoll] = useState(now > endDate);
    const [hasRollsAvailable, setHasRollsAvailable] = useState(user?.cashbackRolls > 0);
    const [winnings, setWinnings] = useState({
        winner: '',
        item1: 0,
        item2: 0,
        item3: 0,
    });

    /*******************/
    /** Refs */
    const item1 = useRef(null);
    const item2 = useRef(null);
    const item3 = useRef(null);

    const item1_wrapper = useRef(null);
    const item2_wrapper = useRef(null);
    const item3_wrapper = useRef(null);

    const item1_result = useRef(null);
    const item2_result = useRef(null);
    const item3_result = useRef(null);

    /*******************/
    /** Functions */
    async function bonusRollItemClickHandler(item) {
        if (!userLoggedIn) {
            navigate('?register');
            return;
        };

        if (!user?.cashbackRolls || user?.cashbackRolls < 1) {
            navigate('?cashier');
            return;
        }

        if (!isEligibleToRoll) return;
        if (isClicked) return;

        setIsClicked(true);

        /********************/
        /** Flip animation */
        setTimeout(() => {
            item1_wrapper.current.className = 'bonus_roll-item-wrapper item-1-wrapper pos-default animate__animated animate__flipCustom';
            item2_wrapper.current.className = 'bonus_roll-item-wrapper item-2-wrapper pos-default animate__animated animate__flipCustom';
            item3_wrapper.current.className = 'bonus_roll-item-wrapper item-3-wrapper pos-default animate__animated animate__flipCustom';
        }, 200);

        /********************/
        /** Sending request to server to get the winnings */
        try {
            const response = await dbBonusRoll();

            if (response.data.winnings) {
                setWinnings({
                    winner: item,
                    win: response.data.winnings.win,
                    lose1: response.data.winnings.lose1,
                    lose2: response.data.winnings.lose2,
                })
            }
            else {
                setErrors([{ message: 'Bonus roll is not available at this time' }]);

                /********************/
                /** Send items to initial position */
                item1_wrapper.current.className = 'bonus_roll-item-wrapper item-1-wrapper pos-initial';
                item2_wrapper.current.className = 'bonus_roll-item-wrapper item-2-wrapper pos-initial';
                item3_wrapper.current.className = 'bonus_roll-item-wrapper item-3-wrapper pos-initial';
                return;
            }
        } catch (error) {
            /********************/
            /** Error handling */
            setErrors([{ message: 'Bonus roll is not available at this time' }]);

            /********************/
            /** Send items to initial position */
            item1_wrapper.current.className = 'bonus_roll-item-wrapper item-1-wrapper pos-initial';
            item2_wrapper.current.className = 'bonus_roll-item-wrapper item-2-wrapper pos-initial';
            item3_wrapper.current.className = 'bonus_roll-item-wrapper item-3-wrapper pos-initial';
            return;
        }

        /********************/
        /** Launch win animations */
        item1_wrapper.current.className = `bonus_roll-item-wrapper item-1-wrapper pos-default ${item === 'item1' && 'item-winner'}`;
        item2_wrapper.current.className = `bonus_roll-item-wrapper item-2-wrapper pos-default ${item === 'item2' && 'item-winner'}`;
        item3_wrapper.current.className = `bonus_roll-item-wrapper item-3-wrapper pos-default ${item === 'item3' && 'item-winner'}`;

        item1.current.classList.add('item-is-flipped');
        item2.current.classList.add('item-is-flipped');
        item3.current.classList.add('item-is-flipped');

        setTimeout(() => {
            item1_result.current.classList.add('result-is-visible');
            item2_result.current.classList.add('result-is-visible');
            item3_result.current.classList.add('result-is-visible');
        }, 200);

        setTimeout(() => {
            setShowNotification(true)
        }, 1500)

        /********************/
        /** Launch close animation */
        setTimeout(async () => {
            setIsEligibleToRoll(false);

            item1_result.current.classList.remove('result-is-visible');
            item2_result.current.classList.remove('result-is-visible');
            item3_result.current.classList.remove('result-is-visible');

            item1.current.classList.remove('item-is-flipped');
            item2.current.classList.remove('item-is-flipped');
            item3.current.classList.remove('item-is-flipped');

            item1_wrapper.current.className = 'bonus_roll-item-wrapper item-1-wrapper pos-initial';
            item2_wrapper.current.className = 'bonus_roll-item-wrapper item-2-wrapper pos-initial';
            item3_wrapper.current.className = 'bonus_roll-item-wrapper item-3-wrapper pos-initial';

            setWinnings({
                winner: '',
                item1: 0,
                item2: 0,
                item3: 0,
            })

            /**************************/
            /** Activating PICK_AND_WIN_RETENTION modal */
            // if (user?.depositsMade < 1 && !user?.retentionNotificationsSent?.includes('PICK_AND_WIN_RETENTION')) {
            //     try {
            //         await dbUpdateUserInfo({
            //             retentionNotificationsSent: `${user?.retentionNotificationsSent || ''}PICK_AND_WIN_RETENTION,`
            //         });
            //     } catch (error) {
            //         console.log(`Error, CashbackRoll, dbUpdateUserInfo, ${error}`)
            //     }
            // }
        }, 9000)
    }

    useEffect(() => {
        /********************/
        /** Launch spread animation */
        let timeout;

        if (((!userLoggedIn || user.depositsMade < 1) || user?.cashbackRolls > 0) && !isClicked && isEligibleToRoll) {
            timeout = setTimeout(() => {
                item1_wrapper.current.className = 'bonus_roll-item-wrapper item-1-wrapper pos-default animation-floating';
                item2_wrapper.current.className = 'bonus_roll-item-wrapper item-2-wrapper pos-default animation-floating';
                item3_wrapper.current.className = 'bonus_roll-item-wrapper item-3-wrapper pos-default animation-floating';
            }, 2000)
        }

        return () => {
            try { clearTimeout(timeout); } catch (error) { }
        }
    }, [winnings]);

    return (
        <>
            <Container fluid="lg" className="bonus_roll-container section-margin">
                <div className="bonus_roll-title-container mb-1 text-center">
                    <CategoryTitle title={text('cashback_roll_title')} className="title-xs mb-0" />
                    <QuestionMark text={text('question_mark_pick_and_win')} />
                </div>
                <p className="font-mid mb-3 text-center">
                    {
                        !userLoggedIn && text('cashback_roll_subtitle')
                    }

                    {
                        userLoggedIn && user?.depositsMade === 0 && text('cashback_roll_subtitle')
                    }

                    {
                        userLoggedIn && user?.depositsMade > 0 && text('cashback_roll_subtitle_first_deposit')
                    }
                </p>

                {/* <img src="https://cherryspins.b-cdn.net/orozino-test/arrow-5.png" className="bonus_roll-arrow" /> */}

                {
                    !isEligibleToRoll && user?.cashbackRolls > 0 && ( // user?.depositsMade > 0 &&  && !isEligibleToRoll
                        <div className="bonus_roll-timer_container">
                            <div className="bonus_roll-timer_wrapper animate__animated animate__faster animate__fadeIn animate__delay-4s">
                                <p className="subtitle-xs mb-1">{text('cashback_roll_next_ticket')}</p>
                                <Timer {...{ endDate }} />
                            </div>
                        </div>
                    )
                }

                {
                    user?.cashbackRolls < 1 && ( // user?.depositsMade > 0 && 
                        <div className="bonus_roll-deposit_container">
                            <div className="bonus_roll-deposit_wrapper animate__animated animate__faster animate__fadeIn animate__delay-4s" >
                                <p className="subtitle-xs">{text('cashback_roll_deposit_title')}</p>
                                <Link to="?cashier" className="btn btn-main">{text('cashback_roll_btn_deposit')}</Link>
                            </div>
                        </div>
                    )
                }

                <div>
                    <ErrorHandler {...{ errors, setErrors }} />

                    <div className="bonus_roll-background"></div>

                    {/* <div className="bonus_roll-attempts_container">
                        <span><FontAwesomeIcon icon={faTicket} /> {text('cashback_roll_tickets_left')} {userLoggedIn ? user?.cashbackRolls : 1}</span>
                    </div> */}

                    <div className={`bonus_roll-items_container ${(!userLoggedIn || user.depositsMade < 1) || (isEligibleToRoll && user?.cashbackRolls > 0) ? 'bonus_roll-items_container_mobile-height' : ''}`}>

                        <div ref={item1_wrapper} className="bonus_roll-item-wrapper item-1-wrapper pos-initial animate__animated animate__faster animate__fadeInUp">
                            <div ref={item1} onClick={() => { bonusRollItemClickHandler('item1') }} className="bonus_roll-item background-blue">
                                {/* <img className="bonus_roll-item-front" src="https://cherryspins.b-cdn.net/orozino-test/card_blue_1.gif" /> */}
                                <img className="bonus_roll-item-front" src="https://spt-assets.b-cdn.net/orozino/bonus-roll/card-3.png" />
                                {/* <video className="bonus_roll-item-front"
                                    loop="true"
                                    autoplay="autoplay"
                                    muted
                                    webkit-playsinline="true"
                                    playsinline="true"
                                >
                                    <source src="https://cherryspins.b-cdn.net/orozino-test/card_blue.webm" type="video/webm" />
                                </video> */}
                                <img className="bonus_roll-item-front bonus_roll-item-back" src={winnings.winner === 'item1' ? itemRollFrontWin : itemRollFrontDefault} />
                                <p ref={item1_result} className={`bonus_roll-item-result ${winnings.winner === 'item1' ? 'item-result-winner' : ''}`}>
                                    {winnings.winner === 'item1' ? <span className="item-result-text">{text('cashback_roll_you_won')}<br /></span> : ''}
                                    <span className="item-result-currency">{user?.currency?.sign}&nbsp;</span>
                                    {winnings.winner === 'item1' ? winnings.win : winnings.lose1}
                                </p>
                            </div>
                        </div>

                        <div ref={item2_wrapper} className="bonus_roll-item-wrapper item-2-wrapper pos-initial animate__animated animate__faster animate__fadeInUp">
                            <div ref={item2} onClick={() => { bonusRollItemClickHandler('item2') }} className="bonus_roll-item background-red">
                                {/* <img className="bonus_roll-item-front" src="https://cherryspins.b-cdn.net/orozino-test/card_red_1.gif" /> */}
                                <img className="bonus_roll-item-front" src="https://spt-assets.b-cdn.net/orozino/bonus-roll/card-2.png" />
                                {/* <video className="bonus_roll-item-front"
                                    loop="true"
                                    autoplay="autoplay"
                                    muted
                                    webkit-playsinline="true"
                                    playsinline="true"
                                >
                                    <source src="https://cherryspins.b-cdn.net/orozino-test/card_red.webm" type="video/webm" />
                                </video> */}
                                <img className="bonus_roll-item-front bonus_roll-item-back" src={winnings.winner === 'item2' ? itemRollFrontWin : itemRollFrontDefault} />
                                <p ref={item2_result} className={`bonus_roll-item-result ${winnings.winner === 'item2' ? 'item-result-winner' : ''}`}>
                                    {winnings.winner === 'item2' ? <span className="item-result-text">{text('cashback_roll_you_won')}<br /></span> : ''}
                                    <span className="item-result-currency">{user?.currency?.sign}&nbsp;</span>
                                    {winnings.winner === 'item2' ? winnings.win : (winnings.winner === 'item1' ? winnings.lose1 : winnings.lose2)}
                                </p>
                            </div>
                        </div>

                        <div ref={item3_wrapper} className="bonus_roll-item-wrapper item-3-wrapper pos-initial animate__animated animate__faster animate__fadeInUp">
                            <div ref={item3} onClick={() => { bonusRollItemClickHandler('item3') }} className="bonus_roll-item background-purple">
                                {/* <img className="bonus_roll-item-front" src="https://cherryspins.b-cdn.net/orozino-test/card_purple_1.gif" /> */}
                                <img className="bonus_roll-item-front" src="https://spt-assets.b-cdn.net/orozino/bonus-roll/card-1.png" />
                                {/* <video className="bonus_roll-item-front"
                                    loop="true"
                                    autoplay="autoplay"
                                    muted
                                    webkit-playsinline="true"
                                    playsinline="true"
                                >
                                    <source src="https://cherryspins.b-cdn.net/orozino-test/card_purple.webm" type="video/webm" />
                                </video> */}
                                <img className="bonus_roll-item-front bonus_roll-item-back" src={winnings.winner === 'item3' ? itemRollFrontWin : itemRollFrontDefault} />
                                <p ref={item3_result} className={`bonus_roll-item-result ${winnings.winner === 'item3' ? 'item-result-winner' : ''}`}>
                                    {winnings.winner === 'item3' ? <span className="item-result-text">{text('cashback_roll_you_won')}<br /></span> : ''}
                                    <span className="item-result-currency">{user?.currency?.sign}&nbsp;</span>
                                    {winnings.winner === 'item3' ? winnings.win : winnings.lose2}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Notification text={text('cashback_roll_notification', { sign: user?.currency?.sign, amount: winnings.win })} showNotification={showNotification} />
        </>
    )
}

function Timer({ endDate }) {
    const [timer, setTimer] = useState('');

    function leadingZero(num) {
        num = num.toString();
        while (num.length < 2) num = "0" + num;
        return num;
    }

    useEffect(() => {
        let end = new Date(endDate);
        let today = new Date();

        let _second = 1000;
        let _minute = _second * 60;
        let _hour = _minute * 60;
        let _day = _hour * 24;
        let distance = Date.parse(end) - Date.parse(today);

        let days = Math.floor(distance / _day);
        let seconds = Math.floor((distance / 1000) % 60);
        let minutes = Math.floor((distance / 1000 / 60) % 60);
        let hours = Math.floor((distance / 1000 / 60 / 60) % 24);

        const timeout = setTimeout(() => {

            if (days < 0 || seconds < 0 || minutes < 0 || hours < 0) {
                setTimer('Available now! Please refresh the page.')
                return;
            }

            setTimer(`${leadingZero(days)}:${leadingZero(hours)}:${leadingZero(minutes)}:${leadingZero(seconds)}`);

        }, 1000);

        return () => {
            clearTimeout(timeout);
        }
    }, [timer])

    return (<span className="bonus_roll-timer">{timer}</span>)
}