import React, { useState, useRef } from "react";
import { dbCorefyWithdraw } from '../utilities/api-functions';
import { Button, Spinner, FloatingLabel, Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';

/** Components */
import WithdrawSummary from "./WithdrawSummary";
import ErrorHandler from "./ErrorHandler";

/** Utilities */
import useText from "../utilities/use-text";

export default function WithdrawRedirect({ amount, paymentMethod }) {
    const user = useSelector((state) => state.user.data)
    const text = useText();

    const [firstName, setFirstName] = useState(user.firstName || '');
    const [lastName, setLastName] = useState(user.lastName || '');
    const [email, setEmail] = useState(user.email || '');
    const [phone, setPhone] = useState(user.phone || '');
    const [street, setStreet] = useState(user.street || '');
    const [city, setCity] = useState(user.city || '');
    const [state, setState] = useState(user.state || '');
    const [zip, setZip] = useState(user.zip || '');

    const [expireYear, setExpireYear] = useState('');
    const [expireMonth, setExpireMonth] = useState('');
    const [cardNumber, setCardNumber] = useState('');

    const [documentId, setDocumentId] = useState('');
    const [pixKey, setPixKey] = useState('');

    const [walletId, setWalletId] = useState('');
    
    const [errors, setErrors] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isRequestComplete, setIsRequestComplete] = useState(false);

    const container = useRef(null);

    const formSubmitHandler = async () => {
        setIsProcessing(true);

        const data = {
            amount: amount,
            service: paymentMethod,
            return_url: window.location.origin,

            /** Player information */
            currency: user?.currency?.code,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            street: street,
            city: city,
            state: state,
            zip: zip,

            /** Card fields */
            exp_year: expireYear,
            exp_month: expireMonth,
            card_number: cardNumber,
            card_holder: `${firstName} ${lastName}`,

            /** Pix fields */
            document_id: documentId,
            pix_key: pixKey,

            /** Crypto */
            wallet_id: walletId
        }

        /*****************/
        /** Making a request */
        try {
            const result = await dbCorefyWithdraw(data);

            /*****************/
            /** Success */
            if (result?.data?.status === 'created') {
                return setIsRequestComplete(true);
            }

            /*****************/
            /** Errors */
            setIsProcessing(false);

            if (result?.data?.errors?.length > 0) {
                setErrors([{ message: text('withdraw_redirect_error', result?.data?.errors[0].title) }]);
            }
            else {
                setErrors([{ message: text('withdraw_redirect_error') }]);
            }
        } catch (error) {
            /*****************/
            /** Errors */
            setIsProcessing(false);

            if (error?.response?.data?.errors?.length > 0) {
                setErrors([{ message: text('withdraw_redirect_error', error?.response?.data?.errors[0]?.title) }]);
            }
            else {
                setErrors([{ message: text('withdraw_redirect_error') }]);
            }
        }

        scrollToTop();
    }

    function scrollToTop() {
        try {
            if (container.current.parentNode.parentNode.parentNode.parentNode.scrollTop > 0) {
                container.current.parentNode.parentNode.parentNode.parentNode.scrollTop = 0;
            }
        } catch (error) { }
    }

    return (
        <div className="withdraw-redirect" ref={container}>
            <h2 className="subtitle font-mid deposit-redirect-title">{text('withdraw_redirect_title')}</h2>
            {
                !isRequestComplete ? (
                    <>
                        <WithdrawSummary sign={user?.currency?.sign} amount={amount} />
                        <ErrorHandler {...{ errors, setErrors }} />
                        <div className={`animate__animated animate__faster animate__fadeIn`}>
                            {
                                {
                                    "payment_card_eur": (
                                        <CardFields {
                                            ...{
                                                firstName,
                                                setFirstName,
                                                lastName,
                                                setLastName,
                                                street,
                                                setStreet,
                                                city,
                                                setCity,
                                                state,
                                                setState,
                                                zip,
                                                setZip,
                                                expireYear,
                                                setExpireYear,
                                                expireMonth,
                                                setExpireMonth,
                                                cardNumber,
                                                setCardNumber
                                            }}
                                        />
                                    ),
                                    "interac_etransfer_cad": (
                                        <InteracFields {
                                            ...{
                                                firstName,
                                                setFirstName,
                                                lastName,
                                                setLastName,
                                                email,
                                                setEmail,
                                                phone,
                                                setPhone
                                            }}
                                        />
                                    ),
                                    "pix_brl": (
                                        <PixFields {
                                            ...{
                                                firstName,
                                                setFirstName,
                                                lastName,
                                                setLastName,
                                                documentId,
                                                setDocumentId,
                                                pixKey,
                                                setPixKey,
                                            }}
                                        />
                                    ),
                                    "bitcoin_eur": (
                                        <CoinspaidFields {
                                            ...{
                                                walletId,
                                                setWalletId
                                            }}
                                        />
                                    ),
                                    "bitcoin_cash_eur": (
                                        <CoinspaidFields {
                                            ...{
                                                walletId,
                                                setWalletId
                                            }}
                                        />
                                    ),
                                    "ethereum_eur": (
                                        <CoinspaidFields {
                                            ...{
                                                walletId,
                                                setWalletId
                                            }}
                                        />
                                    ),
                                    "litecoin_eur": (
                                        <CoinspaidFields {
                                            ...{
                                                walletId,
                                                setWalletId
                                            }}
                                        />
                                    ),
                                    "ripple_eur": (
                                        <CoinspaidFields {
                                            ...{
                                                walletId,
                                                setWalletId
                                            }}
                                        />
                                    ),
                                    "tether_trc20_eur": (
                                        <CoinspaidFields {
                                            ...{
                                                walletId,
                                                setWalletId
                                            }}
                                        />
                                    ),
                                    "tether_erc20_eur": (
                                        <CoinspaidFields {
                                            ...{
                                                walletId,
                                                setWalletId
                                            }}
                                        />
                                    ),
                                }[paymentMethod]
                            }

                            <Button onClick={formSubmitHandler} className="btn-main-lg btn-deposit" disabled={isProcessing}>
                                {
                                    isProcessing ? (
                                        <>
                                            <div>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                &nbsp;&nbsp;
                                                {text('withdraw_redirect_cta_processing')}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {text('withdraw_redirect_cta')}&nbsp;
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </>
                                    )
                                }
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className="deposit-redirect-summary-container animate__animated animate__faster animate__fadeIn">
                        <div className="deposit-redirect-summary">
                            <FontAwesomeIcon icon={faCheckCircle} className="deposit-check-icon mb-2" />
                            <h2 className="title-xs font-full">{text('withdraw_redirect_success_title')}</h2>
                            <p className="font-mid">{text('withdraw_redirect_success_text_1')}</p>
                            <p className="mb-0 font-mid">{text('withdraw_redirect_success_text_2')}</p>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

function CardFields({ firstName, setFirstName, lastName, setLastName, street, setStreet, city, setCity, state, setState, zip, setZip, expireYear, setExpireYear, expireMonth, setExpireMonth, cardNumber, setCardNumber }) {
    const text = useText();

    return (
        <>
            <Row className="mb-2">
                <Col className="pe-1">
                    <FloatingLabel label={text('withdraw_redirect_field_first_name')}>
                        <Form.Control
                            type="text"
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1">
                    <FloatingLabel label={text('withdraw_redirect_field_last_name')}>
                        <Form.Control
                            type="text"
                            value={lastName}
                            onChange={(e) => { setLastName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
            <FloatingLabel label={text('withdraw_redirect_field_address')} className="mb-2">
                <Form.Control
                    type="text"
                    value={street}
                    onChange={(e) => { setStreet(e.target.value); }} />
            </FloatingLabel>
            <Row className="mb-2">
                <Col className="pe-1">
                    <FloatingLabel label={text('withdraw_redirect_field_city')}>
                        <Form.Control
                            type="text"
                            value={city}
                            onChange={(e) => { setCity(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1">
                    <FloatingLabel label={text('withdraw_redirect_field_state')}>
                        <Form.Control
                            type="text"
                            value={state}
                            onChange={(e) => { setState(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col className="">
                    <FloatingLabel label={text('withdraw_redirect_field_zip')}>
                        <Form.Control
                            type="text"
                            value={zip}
                            onChange={(e) => { setZip(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
            <FloatingLabel label={text('withdraw_redirect_field_card_number')} className="mb-2">
                <Form.Control
                    type="text"
                    value={cardNumber}
                    maxLength={16}
                    onChange={(e) => { setCardNumber(e.target.value); }} />
            </FloatingLabel>
            <Row className="mb-0">
                <Col className="pe-1">
                    <FloatingLabel label={text('withdraw_redirect_field_exp_month')}>
                        <Form.Control
                            type="text"
                            value={expireMonth}
                            maxLength={2}
                            onChange={(e) => { setExpireMonth(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1 ">
                    <FloatingLabel label={text('withdraw_redirect_field_exp_year')}>
                        <Form.Control
                            type="text"
                            value={expireYear}
                            maxLength={2}
                            onChange={(e) => { setExpireYear(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
        </>
    )
}

function InteracFields({ firstName, setFirstName, lastName, setLastName, email, setEmail, phone, setPhone }) {
    const text = useText();

    return (
        <>
            <Row className="mb-2">
                <Col className="pe-1">
                    <FloatingLabel label={text('withdraw_redirect_field_first_name')}>
                        <Form.Control
                            type="text"
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1">
                    <FloatingLabel label={text('withdraw_redirect_field_last_name')}>
                        <Form.Control
                            type="text"
                            value={lastName}
                            onChange={(e) => { setLastName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
            <FloatingLabel label={text('withdraw_redirect_field_email')} className="mb-2">
                <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); }} />
            </FloatingLabel>
            <FloatingLabel label={text('withdraw_redirect_field_phone')} className="mb-0">
                <Form.Control
                    type="text"
                    value={phone}
                    onChange={(e) => { setPhone(e.target.value); }} />
            </FloatingLabel>
        </>
    )
}

function PixFields({ firstName, setFirstName, lastName, setLastName, documentId, setDocumentId, pixKey, setPixKey }) {
    const text = useText();

    return (
        <>
            <Row className="mb-2">
                <Col className="pe-1">
                    <FloatingLabel label={text('withdraw_redirect_field_first_name')}>
                        <Form.Control
                            type="text"
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1">
                    <FloatingLabel label={text('withdraw_redirect_field_last_name')}>
                        <Form.Control
                            type="text"
                            value={lastName}
                            onChange={(e) => { setLastName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
            <FloatingLabel label="ID do documento (CPF, CNPJ)" className="mb-2">
                <Form.Control
                    type="text"
                    value={documentId}
                    onChange={(e) => { setDocumentId(e.target.value); }} />
            </FloatingLabel>
            <FloatingLabel label="Chave PIX" className="mb-0">
                <Form.Control
                    type="text"
                    value={pixKey}
                    onChange={(e) => { setPixKey(e.target.value); }} />
            </FloatingLabel>
        </>
    )
}

function CoinspaidFields({ walletId, setWalletId }) {
    const text = useText();

    return (
        <>
            <FloatingLabel label={text('withdraw_redirect_wallet_id')}>
                <Form.Control
                    type="text"
                    value={walletId}
                    onChange={(e) => { setWalletId(e.target.value); }} />
            </FloatingLabel>
        </>
    )
}