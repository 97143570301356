import React, { useState } from "react";
import { Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Form, InputGroup, Spinner, Modal } from 'react-bootstrap';
import { dbSendVerificationCode, dbCheckVerificationCode, dbUpdateUserInfo } from "../utilities/api-functions";
import parsePhoneNumber from 'libphonenumber-js';

/** Components */
import ErrorHandler from "./ErrorHandler";

/** Utilities */
import isValidBonus from "../utilities/is-valid-bonus";
import useText from "../utilities/use-text";

export default function PhoneVerification() {
    const navigate = useNavigate();

    const user = useSelector((state) => state.user.data);
    const global = useSelector((state) => state?.global?.data);
    const bonuses = useSelector((state) => state.bonuses.list);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const text = useText();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    /** First getting all the alternative eligeble bonuses */
    const bonusAltOptions = Object.entries(bonuses)
        .filter(([key, bonus]) => key.includes('PHONE_VERIFICATION_BONUS_ALT'))
        .filter(([key, bonus]) => isValidBonus({ bonus, user, type: 'FREE', showHidden: false, segmentId: global?.segment?.id }))[0];

    /** If alternative bonus qualifies, give the altenative bonus, otherwise give the standard */
    const bonus = bonusAltOptions ? bonusAltOptions[1] : bonuses['PHONE_VERIFICATION_BONUS'];

    const [step, setStep] = useState(1);
    const [phone, setPhone] = useState(user?.phone || '');
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    function checkPhone() {
        if (phone.startsWith('+')) return;

        setPhone(parsePhoneNumber(phone, user?.country?.ISOcode?.toUpperCase())?.number);
    }

    async function updateUserInfo() {
        try {
            await dbUpdateUserInfo({phone: ''});
        } catch (error) {
            console.log(`Error, Phone verify, updateUserInfo, ${error}`)
        }
    }

    const sendVerificationCode = async (type) => {
        setLoading(true);

        try {
            const res = await dbSendVerificationCode({ phone, type });

            if (res?.data?.success) {
                setErrors([]);
                setStep(2);
                setLoading(false);
                return;
            }

            updateUserInfo();
            setErrors([{ message: text('phone_verification_error_general') }]);
            setLoading(false);
        } catch (error) {
            
            updateUserInfo();

            /*******************/
            /** Error */
            if (error?.response?.data?.error === 'PHONE_ALREADY_VERIFIED') {
                setErrors([{ message: text('phone_verification_error_phone_already_verified') }]);
                setLoading(false);
                return;
            }

            if (error?.response?.data?.error === 'WRONG_PHONE') {
                setErrors([{ message: text('phone_verification_error_wrong_phone') }]);
                setLoading(false);
                return;
            }

            if (error?.response?.data?.error === 'ACTIVE_BONUS') {
                setErrors([{ message: text('phone_verification_error_active_bonus') }]);
                setLoading(false);
                return;
            }

            setErrors([{ message: text('phone_verification_error_general') }]);
            setLoading(false);
        }
    }

    const checkVerificationCode = async () => {
        setLoading(true);

        try {
            const res = await dbCheckVerificationCode({ phone: phone, code: code, promoCode: bonus.promoCode });

            if (res?.data?.success) {
                navigate(0)
                return;
            }

            updateUserInfo();
            setErrors([{ message: text('phone_verification_error_general') }]);
            setLoading(false);
        } catch (error) {

            updateUserInfo();
            
            /*******************/
            /** Error */
            if (error?.response?.data?.error === 'WRONG_PHONE') {
                setErrors([{ message: text('phone_verification_error_wrong_phone') }]);
                setLoading(false);
                return;
            }

            if (error?.response?.data?.error === 'ACTIVE_BONUS') {
                setErrors([{ message: text('phone_verification_error_active_bonus') }]);
                setLoading(false);
                return;
            }

            if (error?.response?.data?.error === 'WRONG_BONUS') {
                setErrors([{ message: text('phone_verification_error_general') }]);
                setLoading(false);
                return;
            }

            setErrors([{ message: text('phone_verification_error_general') }]);
            setLoading(false);
        }
    }

    function phoneVerificationExample() {
        let phoneExample;

        switch (user?.country?.name) {
            case 'Brazil':
                phoneExample = '+551130145785';
                break;
            default:
                phoneExample = '+14371222661';
        }

        return `${text('phone_verification_example')} ${phoneExample}`
    }

    return (
        <>
            {
                bonus && (
                    <Container fluid="lg" className="section-margin verify-phone-section">
                        {
                            !userLoggedIn && (
                                <div className="verify-phone-container">
                                    <div className="verify-phone-image-wrapper">
                                        <img src={bonus?.content?.imageUrl} className="verify-phone-image" />
                                    </div>

                                    <div className="verify-phone-content">
                                        <div className="verify-phone-content-text text-center text-md-start">
                                            <h2 className="subtitle verify-phone-title mb-0"> {text('phone_verification_log_out_title', bonus?.content?.badge)}</h2>
                                            {/* <p className="mb-0 font-mid">{text('phone_verification_log_out_text', bonus?.content?.badge)}</p> */}
                                        </div>
                                        <div className="verify-phone-buttons logged-out">
                                            <Link to="?register" className="btn btn-main">{text('phone_verification_btn_claim')}</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            userLoggedIn && !user.phoneVerified && user.depositsMade === 0 && (
                                <div className="verify-phone-container">
                                    <div className="verify-phone-image-wrapper">
                                        <img src={bonus?.content?.imageUrl} className="verify-phone-image" />
                                    </div>

                                    <div className="verify-phone-content">
                                        {
                                            step === 1 && (
                                                <>
                                                    <div>
                                                        <ErrorHandler {...{ errors, setErrors }} />
                                                        <h2 className="subtitle-sm verify-phone-title">{text('phone_verification_log_in_title', bonus?.content?.badge)}</h2>
                                                        <InputGroup id="phone" name="phone" className="mb-0 verify-phone-input">
                                                            <Form.Control
                                                                className="verify-phone-field"
                                                                type="text"
                                                                maxLength="20"
                                                                value={phone}
                                                                placeholder={text('phone_verification_field_phone_label')}
                                                                isValid={phone.length > 7}
                                                                onChange={e => setPhone(e.target.value)} />
                                                        </InputGroup>
                                                        <p className="mb-0 mt-2 font-mid" style={{ fontSize: "13px" }}>
                                                            {phoneVerificationExample()}
                                                        </p>
                                                    </div>

                                                    <div className="verify-phone-buttons">
                                                        <Button className="btn btn-main " onClick={() => { checkPhone(phone); setShowConfirmationModal(true) }} disabled={phone?.toString()?.length <= 7}>
                                                            {
                                                                loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                                            }
                                                            {text('phone_verification_btn_verify_claim')}
                                                        </Button>
                                                    </div>
                                                </>
                                            )
                                        }

                                        {
                                            step === 2 && (
                                                <>
                                                    <div>
                                                        <h2 className="subtitle-sm font-full mb-2">{text('phone_verification_field_code_title')}</h2>
                                                        <InputGroup id="code" name="code" className="verify-code-field">
                                                            <Form.Control
                                                                type="text"
                                                                maxLength="6"
                                                                value={code}
                                                                placeholder={text('phone_verification_field_code_label')}
                                                                onChange={e => setCode(e.target.value)} />
                                                        </InputGroup>
                                                        <ErrorHandler {...{ errors, setErrors }} />
                                                    </div>

                                                    <div className="verify-phone-buttons">
                                                        <Button className="btn btn-main verify-phone-button-confirm" onClick={checkVerificationCode} disabled={loading}>
                                                            {
                                                                loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                                            }
                                                            {text('phone_verification_btn_confirm')}
                                                        </Button>
                                                        <Button className="btn-secondary" onClick={() => {
                                                            setStep(1);
                                                            setErrors([]);
                                                            updateUserInfo();
                                                        }}>
                                                            {text('phone_verification_btn_try_again')}
                                                        </Button>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)} centered>
                            <Modal.Body>
                                <p className="text-center mb-2">{text('phone_verification_confirmation_title')}</p>
                                <p className="title font-full text-center mb-3">
                                    {parsePhoneNumber(phone)?.formatInternational()}
                                </p>
                                <div className="verify-phone-modal-buttons-container">
                                    <Button
                                        className="btn btn-main verify-phone-modal-button"
                                        onClick={() => {
                                            sendVerificationCode('sms');
                                            setShowConfirmationModal(false);
                                        }}
                                    >
                                        {text('phone_verification_btn_sms')}
                                    </Button>

                                    <Button
                                        className="btn btn-main verify-phone-modal-button"
                                        onClick={() => {
                                            sendVerificationCode('call');
                                            setShowConfirmationModal(false);
                                        }}
                                    >
                                        {text('phone_verification_btn_phone_call')}
                                    </Button>

                                    <Button
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            setShowConfirmationModal(false)
                                        }}
                                    >
                                        {text('phone_verification_btn_cancel')}
                                    </Button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </Container>
                )
            }
        </>
    );
}