import React from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

/** Brand pages */
import Home from "./pages/Home";

/** Template pages */
import Game from './spinsoft-core-client-subtree/pages/Game';
import Games from './spinsoft-core-client-subtree/pages/Games';
import Login from './spinsoft-core-client-subtree/pages/Login';
import Profile from './spinsoft-core-client-subtree/pages/Profile';
import Cashier from "./spinsoft-core-client-subtree/pages/Cashier";
import Registration from './spinsoft-core-client-subtree/pages/Registration';
import RegionBlocked from './spinsoft-core-client-subtree/pages/RegionBlocked';
import ResetPassword from './spinsoft-core-client-subtree/pages/ResetPassword';
import ForgotPassword from './spinsoft-core-client-subtree/pages/ForgotPassword';
import VerifyEmail from './spinsoft-core-client-subtree/pages/VerifyEmail';

export default function RenderRoutes() {
    const location = useLocation();
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const urlParams = location.search.split('?')[1] ? location.search.split('?')[1].split('&')[0] : '';

    /** Routes that can be called via "?" */
    const parameterRoutes = () => {
        switch (urlParams) {
            case 'login':
                return <Login />
            case 'register':
                return !userLoggedIn ? <Registration /> : <Navigate to="/" replace />
            case 'forgot-password':
                return !userLoggedIn ? <ForgotPassword /> : <Navigate to="/" replace />
            case 'cashier':
                return userLoggedIn ? <Cashier /> : <Navigate to="?login" state={{ forwardLocation: location }} replace />
            case 'profile':
                return userLoggedIn ? <Profile /> : <Navigate to="?login" state={{ forwardLocation: location }} replace />
            default:
                return null;
        }
    }

    return (
        <>
            <Routes>
                <Route index path='/' element={<Home />} />

                <Route index path='/reset-password' element={!userLoggedIn ? <ResetPassword /> : <Navigate to="/" />} />

                <Route index path='/verify-email' element={!userLoggedIn ? <VerifyEmail /> : <Navigate to="/" />} />

                <Route path="/games" element={<Navigate to="/games/popular" state={{ ...location.state }} replace />} />

                <Route path="/games/:categoryURL" element={<Games />} />

                <Route path="/game/:id" element={
                    userLoggedIn ? <Game /> :
                        <Navigate to={`/?register&game=${location?.pathname?.split('game/')[1]?.split('&')[0]}`} state={{ forwardLocation: location }} replace />
                } />

                <Route path="/region-blocked" element={<RegionBlocked />} />

                <Route path="*" element={<Navigate to="/" />} />
            </Routes>

            {parameterRoutes()}
        </>
    )
}