import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import { Helmet } from "react-helmet-async";
import { isTablet, isDesktop } from 'react-device-detect';

/** Components */
import RegistrationForm from "../components/RegistrationForm";
import OffcanvasBtnClose from '../components/OffcanvasBtnClose';
import FirstDepositPromo from '../../components/FirstDepositPromo';

/** Utilities */
import useText from "../utilities/use-text";

export default function Registration() {
    const navigate = useNavigate();
    const location = useLocation();
    const text = useText();

    const [show, setShow] = useState(true);

    function handleClose() {
        setShow(false);
        setTimeout(() => { navigate(location.pathname); }, 300);
    };

    return (
        <>
            <Helmet>
                <title>{text('page_registration')}</title>
            </Helmet>
            <Offcanvas show={show} onHide={handleClose} placement={isTablet || isDesktop ? 'end' : 'bottom'}>
                <Offcanvas.Body >
                    <div className="offcanvas-registration-section">
                        <FirstDepositPromo page="registration" />
                        <RegistrationForm />
                    </div>
                    <OffcanvasBtnClose onClick={handleClose} />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}