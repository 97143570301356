import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Container, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faClock } from '@fortawesome/free-solid-svg-icons';

/** Utilities */
import useText from "../spinsoft-core-client-subtree/utilities/use-text";

export function ModalWelcomeOffer({ show, setShow }) {
    const user = useSelector((state) => state.user.data);
    const language = useSelector((state) => state.global.data.language);
    const text = useText();
    const navigate = useNavigate();

    let endDate = new Date(user?.cashbackLastPlayed || '1969-01-01');
    endDate = endDate.setDate(endDate.getDate() + 1);

    return (
        <Modal show={show} onHide={() => setShow(false)} centered className="retention-modal">
            <Modal.Body>
                {
                    {
                        "en": <img className="retention-modal-image" src="https://spt-assets.b-cdn.net/orozino/modals/modal-welcome-offer-en.png" />,
                        "pt": <img className="retention-modal-image" src="https://spt-assets.b-cdn.net/orozino/modals/modal-welcome-offer-pt.png" />,
                    }[language]
                }
                
                <Button onClick={() => { setShow(false); navigate('?cashier&promoCode=1st-deposit-300FS-Big-Bass-Bonanza') }} className="btn-main retention-modal-button animate__animated animate__bounceIn" >
                    {text('retention_modals_welcome_cta')}
                </Button>
                <p className="fd_promo_deposit-timer-wrapper animate__animated animate__fadeIn animate__delay-1s">
                    <LotteryDropTimer />
                </p>
            </Modal.Body>
        </Modal>
    )
}

function LotteryDropTimer() {
    const [timer, setTimer] = useState('');

    function leadingZero(num) {
        num = num.toString();
        while (num.length < 2) num = "0" + num;
        return num;
    }

    function getTime() {
        const today = new Date();
        let hours = today.getHours();
        let mimuntes = today.getMinutes();
        let seconds = today.getSeconds();
        let secondsFromStartOfDate = (hours * 60 * 60) + (mimuntes * 60) + seconds;
        let secondsUntilEndOfDate = (24 * 60 * 60) - secondsFromStartOfDate;

        let tempHours = Math.floor((secondsUntilEndOfDate / 60 / 60) % 24);
        let tempMinutes = Math.floor((secondsUntilEndOfDate / 60) % 60);
        let tempSeconds = Math.floor(secondsUntilEndOfDate % 60);

        return `${leadingZero(tempHours)}:${leadingZero(tempMinutes)}:${leadingZero(tempSeconds)}`;
    }

    useEffect(() => {
        const time = getTime();
        !timer && setTimer(time);

        const timeout = setTimeout(() => {
            const time = getTime();
            setTimer(time);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        }

    }, [timer])

    return (
        <span>
            <FontAwesomeIcon icon={faClock} className="me-2" />
            <span>{timer}</span>
        </span>
    )
}